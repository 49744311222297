import React from "react"

const ContactForm = () => {

    return <form name="Contact Form" action="/thanks" method="POST" netlify-honeypot="bot-field" data-netlify="true">
    <input type="hidden" name="form-name" value="Contact Form" />
    <p class="hidden">
        <label>
        Don’t fill this out if you’re human: <input name="bot-field" />
        </label>
    </p>
    <div className="md:flex md:items-center mb-6">
        <div className="md:w-1/4">
        <label className="block font-bold md:text-right mb-1 md:mb-0 pr-2" htmlFor="inline-full-name">
            Naam
        </label>
        </div>
        <div className="md:w-3/4">
            <input name="naam" className="bg-gray-100 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-yellow-500" id="fullname" type="text" />
        </div>
    </div>
    <div className="md:flex md:items-center mb-6">
        <div className="md:w-1/4">
        <label className="block font-bold md:text-right mb-1 md:mb-0 pr-2" htmlFor="inline-full-name">
            E-mail
        </label>
        </div>
        <div className="md:w-3/4">
            <input name="email" className="bg-gray-100 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-yellow-500" id="email" type="email" />
        </div>
    </div>
    <div className="md:flex md:items-center mb-6">
        <div className="md:w-1/4">
            <label className="block font-bold md:text-right text-top mb-1 md:mb-0 pr-4" htmlFor="inline-username">
                Bericht
            </label>
        </div>
        <div className="md:w-3/4">
            <textarea name="bericht" className="bg-gray-100 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-yellow-500" id="description" rows={5} placeholder="Wat wil je aan ons kwijt?" />
        </div>
    </div>
    <div className="md:flex md:items-center">
        <div className="md:w-1/3"></div>
        <div className="md:w-2/3">
            <button type="submit" className="shadow bg-yellow-600 hover:bg-yellow-500 text-lg focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded">
            verstuur
            </button>
        </div>
    </div>
</form>
  }
  
  export default ContactForm