import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactForm from "../components/contactform"
import ContactImg from "../components/contact-img"
import { FaFacebook } from 'react-icons/fa'

const DitIsJCI = () => (
  <Layout>
    <SEO title="Contacteer ons" />
    <div class="pt-48">
      <div class="container mt-4 px-3 mx-auto flex flex-wrap flex-col md:flex-row items-center">
        <div class="border sm:flex">
          <div class="sm:w-2/5 w-full bg-gray-600 bg-cover bg-center text-white">
            <div class="p-8">
              <p class="mb-8">
                Wanneer je graag meer wilt weten over de werking van JCI Dendermonde, 
                interesse hebt om lid te worden of graag aan een vergadering of evenementen wilt deelnemen,
                aarzel dan niet om ons een mail te sturen of onze FB-pagina te raadplegen!
                We beantwoorden uw mail dan zo snel mogelijk!
              </p>
              <p class="mb-4"> 
                <strong>Email:</strong> <a href="mailto:jcidendermonde@gmail.com">jcidendermonde@gmail.com</a><br/>

                <strong>Facebook:</strong> Stuur ons een bericht via onze pagina, we antwoorden snel! 
                <a class="ml-2" href="https://www.facebook.com/jcidendermonde"><FaFacebook></FaFacebook></a><br/>
              </p>
            </div>
          </div>
          <div class="sm:w-3/5 w-full bg-white">
            <div class="p-8">
              <h2>Contact</h2>
              <ContactForm></ContactForm>
            </div>
          </div>
        </div>
        <ContactImg></ContactImg>
      </div>
    </div>
  </Layout>
)

export default DitIsJCI
